<script setup>
    import { defineProps } from "vue";
    import $t from "o365.modules.translate.ts";

    const props = defineProps(['currentSyncProgressPending', 'currentSyncProgressSuccess', 'currentSyncProgressError']);
</script>

<template>
    <div class="d-flex gap-2 align-items-center">
        <div class="progress-stacked w-100" style="height:15px;">
            <div class="progress" role="progressbar" :aria-valuenow="currentSyncProgressSuccess" aria-valuemin="0" aria-valuemax="100" :style="{ width: `${currentSyncProgressSuccess}%` }">
                <div class="progress-bar bg-success"></div>
            </div>

            <div class="progress" role="progressbar" :aria-valuenow="currentSyncProgressError" aria-valuemin="0" aria-valuemax="100" :style="{ width: `${currentSyncProgressError}%` }">
                <div class="progress-bar bg-danger"></div>
            </div>

            <div class="progress" role="progressbar" :aria-valuenow="currentSyncProgressPending" aria-valuemin="0" aria-valuemax="100" :style="{ width: `${currentSyncProgressPending}%` }">
                <div class="progress-bar progress-bar-striped progress-bar-animated bg-primary"></div>
            </div>
        </div>
        <i v-if="currentSyncProgressError > 0" class="bi bi-exclamation-circle-fill text-danger" :title="$t('Expand the dialog to see more')"></i>
    </div>
</template>